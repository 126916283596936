import axios, { AxiosResponse } from 'axios';
import {GetProvidersResponse, Payment} from "../types/payments";
import {APISuccess} from "../types/common";

export const getPayment = async (paymentId: string): Promise<AxiosResponse<Payment>> => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}/payment/details/${paymentId}`);
};

export const initiatePayment = async (paymentId: string, providerId?: string): Promise<AxiosResponse<Payment>> => {
  return axios.post(`${process.env.REACT_APP_BASE_URL}/payment/initiate-payment`, { paymentId, providerId });
};

export const activateAccount = async (activationCode: string): Promise<AxiosResponse<APISuccess>> => {
  return axios.post(`${process.env.REACT_APP_BASE_URL}/company/activate-account`, { activationCode });
};

export const resendActivateAccount = async (activationCode: string): Promise<AxiosResponse<APISuccess>> => {
  return axios.post(`${process.env.REACT_APP_BASE_URL}/company/resend-activate-code`, { activationCode });
};

export const getProviders = async (): Promise<AxiosResponse<GetProvidersResponse>> => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}/payment/providers`);
};
