import {Route, Switch} from "react-router-dom";
import Checkout from "../../Payment/Checkout/Checkout";
import * as React from "react";
import {AppContainer} from "../AppContainer/AppContainer";
import Typography from "@mui/material/Typography";
import ActivateAccount from "../../Company/ActivateAccount/ActivateAccount";

const App = () => {
  return (
    <AppContainer>
      <Switch>
        <Route path="/payment/:id" render={(props) => <Checkout {...props} />} />
        <Route path="/activate/:id" render={(props) => <ActivateAccount {...props} />} />
        <Route path="/callback" render={(props) => <Checkout {...props} />} />
        <Route path="*">
          <Typography component="h1" variant="h4" align="center" style={{margin: '50px'}}>
            PagaPay
          </Typography>
        </Route>
      </Switch>
    </AppContainer>
  );
}

export default App;
