
export enum PaymentStatus {
  created = 'Created',
  initiated = 'Initiated',
  cancelled = 'Cancelled',
  authorisation_failed = 'Authorisation failed',
  executing = 'Executing',
  rejected = 'Rejected',
  executed = 'Executed',
  expired = 'Expired'
}

export enum Currency {
  GBP
}

export interface PaymentOrderItem {
  description: string;
  amount: number;
  currency: Currency;
  total: string;
}

export interface Provider {
  id: string;
  name: string;
  logo: string;
  icon: string;
}

export interface Payment {
  id: string;
  url: string;
  qrCode: string;
  status: PaymentStatus;
  statusCode: keyof typeof PaymentStatus,
  payAuthUrl?: string,
  reference?: string,
  amount?: number,
  currency?: Currency,
  total?: string,
  description?:string,
  orderItems?: PaymentOrderItem[],
  beneficiary?: string
  providerSelected?: Provider;
  redirectUrl?: string;
}

export interface GetProvidersResponse {
  providers: Provider[]
}
