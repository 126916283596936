import React, {useEffect, useState} from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {RouteComponentProps} from "react-router-dom";
import PaymentDetails from "./PaymentDetails/PaymentDetails";
import {Payment, PaymentStatus as PaymentStatusEnum} from "../../../types/payments";
import {getPayment} from "../../../services/apiService";
import {LinearProgress} from "@mui/material";
import Pusher from "pusher-js";
import {PaymentStatusSet} from "../../../types/events";
import InitiatePayment from "./InitiatePayment/InitiatePayment";
import PaymentStatus from "./PaymentStatus/PaymentStatus";

interface CheckoutRouteProps {
    id?: string;
}

const Checkout: React.FC<RouteComponentProps<CheckoutRouteProps>> = (props): JSX.Element => {
    const [activeStep, setActiveStep] = useState<number>(-1);
    const [payment, setPayment] = useState<Payment>();
    const [canPay, setCanPay] = useState<boolean>(false);
    let paymentId = props.match?.params.id ?? null;

    const urlParams = new URLSearchParams(props.location?.search);

    if (urlParams.has('single_immediate_payment_id')) {
      paymentId = urlParams.get('single_immediate_payment_id')
    }

    useEffect(() => {
        if (paymentId) {
            getPayment(paymentId)
              .then(response => {
                  setPayment(response.data)
              })
              .catch(error => {
                  console.log(error)
              });
        }
    }, [props, setPayment, paymentId]);

    useEffect(() => {
      switch (payment?.status) {
        case PaymentStatusEnum.created:
          setCanPay(true);
          setActiveStep(-1);
          break;
        case PaymentStatusEnum.initiated:
          setCanPay(true);
          setActiveStep(0);
          break;
        case PaymentStatusEnum.executing:
          setActiveStep(1);
          setCanPay(false);
          break;
        case PaymentStatusEnum.expired:
        case PaymentStatusEnum.cancelled:
        case PaymentStatusEnum.authorisation_failed:
        case PaymentStatusEnum.rejected:
        case PaymentStatusEnum.executed:
          setActiveStep(3);
          setCanPay(false);
          break;
        default:
          setCanPay(false);
      }
    }, [payment, setCanPay]);

    useEffect(() => {
      const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY as string, {
        cluster: 'eu'
      });

      const channel = pusher.subscribe(`payment-${paymentId}`);

      channel.bind('payment-status-set', function(data: any) {
        const paymentStatus: PaymentStatusSet = JSON.parse(data);

        if (payment) {
          setPayment({...payment, ...paymentStatus});
        }
      });

    }, [paymentId, setPayment, payment]);

    return (
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          {payment ? <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
              <Step>
                <StepLabel>Initiated</StepLabel>
              </Step>
              <Step>
                <StepLabel>Processing</StepLabel>
              </Step>
              <Step>
                <StepLabel>{activeStep === 3 ? payment?.status : "Executed"}</StepLabel>
              </Step>
            </Stepper>
            {!canPay ? <PaymentStatus payment={payment} /> : (
              <>
                {payment.total && <PaymentDetails
                    {...props}
                    total={payment.total}
                    description={payment.description}
                    orderItems={payment.orderItems}
                />}
                <InitiatePayment payment={payment} setPayment={setPayment} />
              </>
            )}
            </Paper> : <LinearProgress style={{marginTop: '50px'}}/>}
        </Container>
    );
}

export default Checkout;
