import * as React from "react";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      Copyright © <Link color="inherit" href="/">PagaPay</Link>® {new Date().getFullYear()}.<br /><br />
      A <Link color="inherit" href="https://www.digitunit.com" target="_blank">DigitUnit</Link>® development.<br />
      <span style={{fontSize: '12px'}}>PagaPay® is a direct product of Yan Digital Ltd - Registered Office: 840 Ibis Court Centre Park, Warrington, England, WA1 1RL.<br />
        Registered Number: 11521484. Registered in England & Wales.</span>
    </Typography>
  );
}

const theme = createTheme();

export const AppContainer: React.FC = ({children}): JSX.Element => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <AppBar
      position="absolute"
      color="default"
      elevation={0}
      sx={{
        position: 'relative',
        borderBottom: (t) => `1px solid ${t.palette.divider}`,
      }}
    >
      <Toolbar>
        <Typography variant="h6" color="inherit" noWrap>
          <img src="/logo.png" width={100} alt="PagaPay" />
        </Typography>
      </Toolbar>
    </AppBar>
    {children}
    <Copyright />
  </ThemeProvider>
);
