import * as React from "react";
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select, SelectChangeEvent,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Provider} from "../../../../types/payments";
import {useEffect, useState} from "react";
import {getProviders} from "../../../../services/apiService";

export interface SelectProviderProps {
  providerId?: string;
  setProvider: (provider: Provider) => void
}

const SelectProvider: React.FC<SelectProviderProps> = ({providerId, setProvider}): JSX.Element => {

  const [providers, setProviders] = useState<Provider[]>([]);

  useEffect(() => {
    getProviders()
      .then(response => {
        setProviders(response.data.providers);
      });
  }, []);

  const handleSelect = (event: SelectChangeEvent) => {
    const provider = providers.find(provider => provider.id === event.target.value);

    if (provider) {
      setProvider(provider);
    }
  }

  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>Pay with</Typography>
      {providers.length > 0 && <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Bank institution</InputLabel>
        <Select
          value={providerId ?? ''}
          onChange={handleSelect}
          input={<OutlinedInput label="Bank institution" />}
        >
          {providers.map((provider) => (
            <MenuItem key={provider.id} value={provider.id}>
              <img src={provider.logo} alt={provider.name} width={70} style={{float: "left"}} />
              <ListItemText primary={provider.name} style={{width: "calc(100% - 70px)", float: "right"}} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>}
    </>
  );
};

export default SelectProvider;
