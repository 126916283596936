import * as React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {PaymentOrderItem} from "../../../../types/payments";

interface PaymentDetailsProps {
    total: string;
    description?: string;
    orderItems?: PaymentOrderItem[];
}

const PaymentDetails: React.FC<PaymentDetailsProps> = ({total, description, orderItems}): JSX.Element => {
    return (
        <>
            {description && <>
                <Typography variant="h6" gutterBottom>Description</Typography>
                <Typography variant="body1">{description}</Typography>
            </>}
            <List disablePadding>
                {orderItems && orderItems.map((product, index) => (
                    <ListItem key={index} sx={{ py: 1, px: 0 }}>
                        <ListItemText primary={product.description} />
                        <Typography variant="body2">{product.total}</Typography>
                    </ListItem>
                ))}
                <ListItem sx={{ py: 1, px: 0 }}>
                    <ListItemText primary="Total" />
                    <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>{total}</Typography>
                </ListItem>
            </List>
        </>
    );
}

export default PaymentDetails;