import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from "react-router-dom";
import {Container, LinearProgress, Paper} from "@mui/material";
import {activateAccount, resendActivateAccount} from "../../../services/apiService";
import LoadingButton from "@mui/lab/LoadingButton";

interface ActivateAccountProps {
  id?: string;
}

const ActivateAccount: React.FC<RouteComponentProps<ActivateAccountProps>> = (props): JSX.Element => {
  const [failedMsg, setFailedMsg] = useState<string|null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [resendLoading, setResendLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>();

  const activationCode = props.match?.params.id ?? null;

  useEffect(() => {
    if (activationCode) {
      activateAccount(activationCode)
        .then(response => {
          if (response.data.success) {
            setSuccess(true);
          }
        })
        .catch(error => {
          const violation = error.response.data.exception?.violations;

          if (violation !== 'Invalid activation code.') {
            setSuccess(false);
          }

          setFailedMsg(violation);
        })
        .finally(() => setLoading(false));
    }
  }, [activationCode, setSuccess, setFailedMsg]);

  const resendCode = () => {
    if (activationCode) {
      setResendLoading(true);
      resendActivateAccount(activationCode)
        .then(response => {
          if (response.data.success) {
            setSuccess(true);
            setFailedMsg('Code sent successfully. Please check your inbox or spam mailbox.');
          }
        })
        .catch(error => {
          setResendLoading(false);
          setFailedMsg(error.response.data.exception?.violations);
        })
        .finally(() => setResendLoading(false));
    }
  }

  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
      {loading ? <LinearProgress style={{marginTop: '50px'}}/> :
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          {(success && !failedMsg) ? 'Account Activated!' : failedMsg}

          {success === false &&
            <LoadingButton
              onClick={resendCode}
              loading={resendLoading}
              variant="contained"
              fullWidth
              size="large"
              style={{marginTop: '30px'}}
            >
              Resend code
            </LoadingButton>
          }
        </Paper>
      }
    </Container>
  );
}

export default ActivateAccount;
