import {Payment, PaymentStatus as PaymentStatusEnum} from "../../../../types/payments";
import * as React from "react";
import {Button, LinearProgress} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useEffect} from "react";

export interface PaymentStatusProps {
  payment: Payment;
}

const PaymentStatus: React.FC<PaymentStatusProps> = ({payment}): JSX.Element => {

  useEffect(() => {

  }, [payment]);

  return (
    <>
      {payment.status === PaymentStatusEnum.executing
        ? <LinearProgress style={{marginBottom: '20px'}} />
        : (
          <Typography variant="h5" gutterBottom>
            {payment.status}
          </Typography>
        )}
      <Typography variant="subtitle1">
        {payment.status === PaymentStatusEnum.executing && `Processing your payment of ${payment.total} to ${payment.beneficiary}.`}
        {payment.status === PaymentStatusEnum.expired && `Please contact the store and try again the purchase.`}
        {[PaymentStatusEnum.rejected, PaymentStatusEnum.authorisation_failed].includes(payment.status) && payment.status && `Please contact your bank about this transaction.`}
        {payment.status === PaymentStatusEnum.cancelled && `You have cancelled this transaction.`}
        {payment.status === PaymentStatusEnum.executed && `Payment executed with success.`}
      </Typography>
      {payment.status !== PaymentStatusEnum.executing && payment.redirectUrl && (
        <Button
          onClick={() => { window.location.href = payment.redirectUrl as string }}
          variant="contained"
          fullWidth
          size="large"
          style={{marginTop: '30px'}}
        >
          Back to store
        </Button>
      )}
    </>
  );
};

export default PaymentStatus;
