import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import {initiatePayment} from "../../../../services/apiService";
import {Payment, Provider} from "../../../../types/payments";
import {Dispatch, SetStateAction, useState} from "react";
import SelectProvider from "../SelectProvider/SelectProvider";

export interface InitiatePaymentProps {
  payment: Payment;
  setPayment: Dispatch<SetStateAction<Payment | undefined>>
}

const InitiatePayment: React.FC<InitiatePaymentProps> = ({payment, setPayment}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);

  const goToAuthUrl = () => {
    if (payment?.payAuthUrl) {
      window.location.href = payment.payAuthUrl;
    } else if (payment?.id) {
      setLoading(true);
      initiatePayment(payment.id, payment.providerSelected?.id)
        .then(response => {
          setPayment(response.data);
          if (response.data.payAuthUrl) {
            setLoading(false);
            window.location.href = response.data.payAuthUrl;
          }
        }).catch(error => {
        console.log(error)
      });
    }
  }

  const handleSetProvider = (provider: Provider | undefined) => {
    setPayment({...payment, providerSelected: provider});
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>Paying to</Typography>
          <Typography gutterBottom>{payment.beneficiary}</Typography>
        </Grid>
      </Grid>
      <SelectProvider providerId={payment.providerSelected?.id} setProvider={handleSetProvider} />
      <LoadingButton
        onClick={goToAuthUrl}
        loading={loading}
        variant="contained"
        fullWidth
        size="large"
        style={{marginTop: '30px'}}
        disabled={payment.providerSelected === undefined}
      >
        Continue to your bank
      </LoadingButton>
      <Typography style={{textAlign: 'center', marginTop: '10px', fontSize: '12px'}}>
        Powered by <img src="/truelayer_logo.svg" alt="TrueLayer logo" width={80} style={{verticalAlign: 'middle'}} />
      </Typography>
      <Typography style={{textAlign: 'center', marginTop: '60px', fontSize: '12px'}}>
        <em>
          <span>By continuing you are permitting TrueLayer to initiate a payment from your bank account.
          You also agree to TrueLayer's <a href="https://truelayer.com/enduser_tos" target="_self">End User </a>
          </span>
          <a href="https://truelayer.com/enduser_tos" target="_self">
            <span>Terms of Service</span>
          </a>
          <span> and </span><span><a href="https://truelayer.com/privacy" rel="noreferrer" target="_blank">Privacy Policy</a></span>.
        </em>
      </Typography>
    </>
  );
};

export default InitiatePayment;
